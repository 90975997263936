$color-gray= #52514e
$color-gray-2= #666
$color-gray-light= #ebebe3
$color-green-midnight= #697b70
$color-gold= #ffd6b9
$color-gray-border= #e2e8f0
$color-gray-medium= #f2f2f2
$color-green= #5bba47
$color-green-dark= #5bba48
$color-dark-gray= #434242
$color-dark-gray-medium= #6f6f6f
$color-gray-100= #F5F5F5
$color-gray-200= #d6d6d6
$color-black-100= #272727
$color-red= #FF4252
$color-gray-300= #ececec
$color-blue= #0671e3
$color-gray-400= #eff0f2
$color-bnn= #ffd400
$color-bnn-border= #ffd400
$color-bnn-border-2= #ffdd00
$color-bnn-btn-text= #000000
$color-bnn-gray= #F5F5F7
$color-bnn-blue= #338DF7
$color-bnn-blue-2= #0095CC
$color-bnn-border-btn= #E5E5E5
$color-bnn-border-dark-btn= #A5A5A5
$color-bnn-border-light= #5C5C5C
$color-bnn-red= #ff381d
$color-bnn-red-2= #FF2121
$color-bnn-gray-light= #CCCCCC
$color-bnn-gray-light-2= #F8F8F8
$color-bnn-gray-medium= #151515
$color-bnn-gray-medium-2= #818181
$color-btn-base= white
$color-bnn-orange= #FF6200
$color-bnn-orange-2= #ff8901
$color-warning= #eba614



small = '(min-width: 320px) and (max-width: 767px)'
medium = '(min-width: 768px) and (max-width: 1024px)'
min-medium = '(min-width: 768px)'
medium-landscape = '(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)'
large = '(min-width: 1025px) and (max-width: 1280px)'
xlarge = '(min-width: 1281px) and (max-width: 1440px)'
xxlarge = '(min-width: 1441px)'

tablet = '(min-width: 768px)'
desktop = '(min-width: 1024px)'

@media (min-width: 992px) 
    .gallery-md
        margin-top: 0.5rem
        display flex
        flex-wrap wrap
        width 490px
    .relative-md
        position: relative !important
    .absolute-md
        position: absolute !important    
    .float-left-md
        float: left
    .sticky-md
        position: fixed !important
    .mw-490
        min-width: 80px !important
    .mh-621
        min-height: 142px !important
    .top-md-0 
        top: 20px
    .h-md-2
        height: 0.5rem 
    .pd-0
        padding: none;
    .pd-1
        padding: none; 
    .pdr-0
        padding-right: 0rem !important
    .pdr-1
        padding-right: 0.5rem !important 
    .pdr-2
        padding-right: 1rem !important      
    .pdr-3
        padding-right: 1.2rem !important    
    .pdr-4
        padding-right: 1.5rem !important  
    .pdr-5
        padding-right: 2rem !important  
    .pdr-6
        padding-right 3rem !important             
    .pdl-0
        padding-left: 0rem !important
    .pdl-1
        padding-left: 0.5rem !important  
    .pdl-2
        padding-left: 1rem !important       
    .pdl-3
        padding-left: 1.2rem !important    
    .pdl-4
        padding-left: 1.5rem !important    
    .pdl-5
        padding-left: 2rem !important  
    .pdl-6
        padding-left: 3rem !important        
       
.align-flex-end
    align-items: flex-end       
.justify-content-between
    justify-content: space-between

.text-underline
    text-decoration: underline      
.text-red
    color: $color-red  
.text-blue
    color: $color-blue          
.text-green
    color: $color-green

.text-line-through
    text-decoration: line-through

.pd-0
    padding: 0 .0em;
.pd-1
    padding: 0 .25em; 

.bg-gray
    background-color: $color-gray

.bg-gray-light
    background-color: $color-gray-light 

.bg-green-midnight
    background-color: $color-green-midnight 

.bg-gold
    background-color: $color-gold          

hr.gray
    background-color:$color-gray-border
    height: 1px
    border: 0

.w-full
    width: 100%

.font-bold
    font-weight: bold   

.font-light 
  font-weight: 300


.font-normal 
  font-weight: 400


.font-medium 
  font-weight: 500

.fit-contain
  object-fit: contain


.font-semibold 
  font-weight: 600


.text-dark-gray
    color: $color-dark-gray

.text-white
	color: white !important

.text-bnn-blue
    color: $color-bnn-blue   

.text-label-summary
   font-size: 1.5rem
.text-cost-summary
   font-size: 1.875rem
@media (max-width: 768px)
 .text-label-summary
    font-size: 1.125rem
 .text-cost-summary
    font-size: 1.325rem    
