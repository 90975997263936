

.h-full {
  margin-top: 80px;
  min-height: 100dhv;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr-dash {
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  width: 50%;
}

.hr-dash:before {
  content: '';
  position: absolute;
  border-bottom: 5px dashed $color-gray-200;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
}
